import { SplashScreen } from "@capacitor/splash-screen";

const SplashScreenManager = {
  hide() {
    return SplashScreen.hide();
  },

  show(options) {
    return SplashScreen.show(options);
  },
};

export default {
  instance: SplashScreenManager,

  install(Vue) {
    Vue.prototype.$plugins.splashScreen = SplashScreenManager;
  },
};
